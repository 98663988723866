import React, { useState, useEffect } from "react";
import data from "../../directory_structure.json";
import Directory from "../../components/Directory";
import {useNavigate, useParams} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import File from "../Viewer/File";
import '../../styles/subject.css'
import DirButtons from "../../components/DirButtons";


function Subject({ subject }) {

    const [jsonData, setJsonData] = useState(null);

    const location = useLocation();
    const dir = useParams();
    const [fileContent, setFileContent] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        // The data imported from data.json is already a JavaScript object
        const fetchFileContent = async () => {
            try {
                console.log(`/api/${subject}/${location.pathname}`);
                const response = await fetch(`/api/${subject}${location.pathname}`); // Replace with your actual path
                const content = await response.text();
                setFileContent(content);
            } catch (error) {
                console.error('Error fetching file content:', error);
            }
        }
        fetchFileContent();
        let directories = data;
        console.log(jsonData);
        if (dir !== null && dir['*'].split('/')[0] !== '')
            dir['*'].split('/').forEach(d => directories = directories[d]);
        setJsonData(directories);

        if(document.getElementById("subject-content").textContent.length < 3){
            navigate("/");
        }
    }, [location.pathname, subject, dir]);

    function getDirectories() {
        const isSubject = location.pathname === `/`;
        return  (<div className={isSubject? "subject-container":"directory-container"}> { Object.keys(jsonData).map((key) => (
            // We want to filter out any images, so we put them in a separate section
           ( (!(key.includes("gif") || key.includes("GIF"))) && !key.includes(".txt") && !key.includes("DS_Store"))  ? <Directory key={key} text={key} isImage={false} isSubject={isSubject}/> : null
        )) }</div>);
    }

    function getAssets() {
        return  (<div className={"directory-container"}> { Object.keys(jsonData).map((key) => (
            // We want to filter out any images, so we put them in a separate section
            ((key.includes("gif") || key.includes("GIF")) || key.includes("clients.txt") )? <Directory key={key} text={key} isImage={true}/> : null
        )) }</div>);
    }

    return (
        <div id={"subject-content"}>
            {jsonData && (getDirectories())}
            {jsonData && (getAssets())}
            {(location.pathname.includes(".java") || location.pathname.includes(".class")|| location.pathname.includes(".cpp")) && <File />}
        </div>
    );
}

export default Subject;
