// App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams, useLocation } from 'react-router-dom';
import {replaceEvenStrings} from "../utility/formatter";
import '../styles/dirButtons.css'
import { useNavigate } from 'react-router-dom';
function DirButtons() {
    const location = useLocation();
    const [pathname, setPathname] = useState("");
    const [counter, setCounter] = useState(1);
    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    const navigate = useNavigate();

    const redirectToURL = (url, n) => {

        const currentPathname = window.location.pathname;

        // Split the pathname into segments
        const segments = currentPathname.split('/');
        let levelsUp = segments.length - n - 1;
        if (levelsUp === 0) {
            levelsUp = -segments.length;
        }
        // Remove specified number of segments to navigate up
        const newPathSegments = segments.slice(0, -levelsUp);

        // Join the segments to form the new pathname
        const newPathname = newPathSegments.join('/');

        // Navigate to the higher level
        navigate(newPathname);
    };

    function repeatStream(stream, n) {
        return
    }


    return (
        <div id = "dirButtons">
            <span onClick={()=>redirectToURL('', -1)} key={-1 + "h1"}>{"🏠︎ >"}</span>
            {pathname.split("/").map((d, index) => { return( d !== ''? <span onClick={()=>redirectToURL(d, index)} key={index + "h1"}>{replaceEvenStrings(d)+" >"}</span> : '')} )}
        </div>
    );
};

export default DirButtons;
