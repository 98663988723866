import React, {useState} from 'react';
import '../styles/directory.css';
import {useNavigate} from "react-router-dom"; // Import the CSS file for styling
import {replaceEvenStrings} from "../utility/formatter";
import { useLocation } from 'react-router-dom';
import '../styles/directory.css'
const Directory = ({ text , isImage, isSubject}) => {
    const navigate = useNavigate();

    const location = useLocation();
    const currentUrl = location.pathname;
    const [currentText, setCurrentText] = useState(text);
    const redirectToURL = (url) => {
        console.log("url is: "+ `${currentUrl}/${text}`)
        navigate(url.replace("//", "/"));
    };
    const handleDownload = async (fileUrl, fileName) => {
        try {
            // Replace 'your_file_url' with the actual URL of the file you want to download


            // Make a fetch request to the file URL
            const response = await fetch("/api"+fileUrl);

            // Check if the request was successful
            if (!response.ok) {
                throw new Error(`Failed to fetch the file. Status: ${response.status}`);
            }

            // Get the file content as a Blob
            const blob = await response.blob();

            // Create a temporary download link
            const downloadLink = document.createElement('a');

            // Create an object URL from the Blob
            const objectUrl = URL.createObjectURL(blob);

            // Set the href attribute to the object URL
            downloadLink.href = objectUrl;

            // Set the download attribute with the desired file name
            downloadLink.download = fileName;

            // Append the link to the document
            document.body.appendChild(downloadLink);

            // Trigger a click on the link to start the download
            downloadLink.click();

            // Remove the link and revoke the object URL
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(objectUrl);
        } catch (error) {
            console.error('Error downloading file:', error.message);
            // Handle the error, e.g., show an error message to the user
        }
    };


    const handleClick = ()=>{
        const link = currentUrl !== '/' ? `${currentUrl}/${text}` : `/${text}`;

        if(isImage){
            handleDownload(link, text);
        }else{
            redirectToURL(link)
        }
    }

    const handleMouseOver = (e) => {
        setCurrentText("Download \"" + text + "\"");
    }
    const handleMouseOut = (e) => {
        setCurrentText(text);
    }

    const getContent =  () =>{
        if(isSubject){
            return <figure>
                <img src={`/Images/cover-pages/${text}.jpg`} alt="subject" />
                <p>return <p className="subject-text">{replaceEvenStrings(currentText)}</p></p>
            </figure>
        }
        else{
            return <p className="text">{replaceEvenStrings(currentText)}</p>
        }
    }

    return (
        <div>
            {<div onMouseOut={isImage? handleMouseOut: null} onMouseOver={isImage? handleMouseOver: null} onClick={handleClick}
                    className={"directory" + (isImage? " image ": "") + (isSubject? " subject ": "") }>
                {getContent()}
            </div>}
        </div>
    );
};

export default Directory;
