// App.js

import React from 'react';
import {BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate} from 'react-router-dom';
import logo from "./logo.png";
import Subject from './Pages/Home/Subject';
import './App.css';
import DirButtons from "./components/DirButtons";
import BackgroundStars from "./components/BackgroundStars";
import Callback from './components/Callback';
import Login from "./components/Login";
import Contact from "./Pages/About/Contact";
import GPT4 from "./Pages/GPT4";
const Logo =() =>{
    const navigate = useNavigate();
    const redirectToURL = (url) => {
        navigate(url);
    };
    return <figure  onClick={()=>redirectToURL("/")}  id={"logo"}>
        <img src={logo}/>
        <p>Barmja</p>
    </figure>;
}

const App = () => {

    return (
        <Router>
            <div>
                <nav id={"nav-bar"}>
                    <Logo/>
                    <Link to="/">Home</Link>
                    <Link to="/contact">Contact</Link>
                    <DirButtons/>
                </nav>
                <main id={"main"}>
                    <Routes>
                        <Route path="/*" element={<Subject/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/ziad-gpt4" element={<GPT4/>}/>
                    </Routes>
                </main>
            </div>
        </Router>
    );
};

export default App;
