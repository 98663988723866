import {useState} from "react";
import '../../styles/toggle.css';
function Toggle ({isFirstChoice, setIsFirstChoice}) {

    const toggleButtons = () => {
        setIsFirstChoice(!isFirstChoice);
    };

    return (
        <div className={"toggle-container"}>
                <button disabled={isFirstChoice} onClick={toggleButtons}>Train</button>

                <button disabled={!isFirstChoice} onClick={toggleButtons}>Solution</button>

        </div>
    );
}

export default Toggle;