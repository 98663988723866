import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-java';
import '../../styles/file.css';
import Ask from "../../components/Ask";
import Toggle from "./Toggle";
import CodeSubmit from "../../components/CodeSubmit";
function File() {
    const [fileContent, setFileContent] = useState('');
    const [questionContent, setQuestionContent] = useState('');

    const [isFirstChoice, setIsFirstChoice] = useState(true);
    const [loading, setLoading] = useState(true);
    const dir = useParams();
    const urlArray = dir['*'].split('/');
    const directory =
        urlArray[urlArray.length - 3]
            ? `${urlArray[urlArray.length - 3]}/${urlArray[urlArray.length - 2]}`
            : urlArray[urlArray.length - 2];
    const filename = urlArray[urlArray.length - 1];

    const location = useLocation();

    useEffect(() => {
        console.log("PATH "+`/api${location.pathname}`);
        Prism.highlightAll();
    }, [fileContent]);
    useEffect(() => {
        Prism.highlightAll();
    }, [isFirstChoice]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("PATH "+`${location.pathname}`)
                const responseJava = await axios.get(`/api${location.pathname}`);
                setFileContent(responseJava.data.replaceAll("!=", "!‎="));
                console.log("code "+responseJava.data);
                const responseQuestion = await axios.get(`/api${location.pathname}`.replace('.java', 'Question.txt').replace('.cpp', 'Question.txt'));
                 console.log("Question "+responseQuestion.data);
                setQuestionContent(responseQuestion.data.replaceAll("!=", "!‎="));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching file content:', error);
            }
        };

        fetchData();
    }, [directory, filename, location.pathname]);

    return (
        <div className = "full-viewer">

            <Toggle isFirstChoice={isFirstChoice} setIsFirstChoice={setIsFirstChoice}/>

            {!isFirstChoice && <div>
               <div className={"code-viewer"}>
                    <pre className="language-java">
                        {loading && <div className={"loading frr"}></div>}
                        <code id={"codeBlock"}>{location.pathname.includes("examples") ? (fileContent).slice(0, -1078): (fileContent)}</code>
                    </pre>
                </div>
                <Ask/>
            </div>}

            {isFirstChoice && <div>
                {loading && <div className={"loading fe155"}></div>}
                <h2 style={{color: "whitesmoke"}} className={"question-content"}  dangerouslySetInnerHTML={{ __html: questionContent.replace("Python", "Java").replace("python", "java") }}></h2>
                    <CodeSubmit/>
                </div>
            }
            <br/>

        </div>
    );
}

export default File;
