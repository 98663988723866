import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/social.css';
// Functional component for the URL button
const Social = ({ url, buttonText }) => {
    return (
        <Link to={url}>
            <button id={buttonText}>{buttonText}</button>
        </Link>
    );
};

export default Social;