const replacements = [
    ["_", " "],
    ["Chapter_", "Chapter "]
]

export function replaceEvenStrings(inputString) {
    const match = inputString.match(/^(fig|Fig)(\d+)_(\d+)(?:_(\d+)(?:-(\d+))?)?$/);

    if (match) {
        const prefix = match[1];
        const firstNumber = match[2];
        const secondNumber = match[3];
        const thirdNumberStart = match[4];
        const thirdNumberEnd = match[5];

        let formattedString = `Figure ${parseInt(firstNumber)}.${parseInt(secondNumber)}`;

        if (thirdNumberStart) {
            formattedString += ` - ${parseInt(firstNumber)}.${parseInt(thirdNumberStart)}`;
            if (thirdNumberEnd) {
                formattedString += ` - ${parseInt(firstNumber)}.${parseInt(thirdNumberEnd)}`;
            }
        }

        return formattedString;
    } else {
        const match = inputString.match(/^(fig|Fig)(\d+)_(\d+)(?:-(\d+))?$/);

        if (match) {
            const prefix = match[1];
            const firstNumber = match[2];
            const secondNumber = match[3];
            const thirdNumberStart = match[4];

            let formattedString = `Figure ${parseInt(firstNumber)}.${parseInt(secondNumber)}`;

            if (thirdNumberStart) {
                formattedString += ` - ${parseInt(firstNumber)}.${parseInt(thirdNumberStart)}`;
            }

            return formattedString;
        } else {
            const match = inputString.match(/^(exercise|Fig)(\d+)_(\d+)(?:-(\d+))?$/);

            if (match) {
                const firstNumber = match[2];
                const secondNumber = match[3];
                const thirdNumberStart = match[4];

                let formattedString = `Exercise ${parseInt(firstNumber)}.${parseInt(secondNumber)}`;

                if (thirdNumberStart) {
                    formattedString += ` - ${parseInt(firstNumber)}.${parseInt(thirdNumberStart)}`;
                }

                inputString = formattedString;
            }
        }
    }
    // Loop through the array of replacements
    for (let i = 0; i < replacements.length; i++) {
        // Check if the index is even and the replacement array has two elements
        if (i % 2 === 0) {
            const pattern = replacements[i][0];
            const replacement = replacements[i][1];

            inputString = inputString.replace(pattern, replacement);
        }
    }

    return inputString.replace("book-examples", "Book Examples");
}

