import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../styles/ai.css';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-java';
import '../styles/file.css';

function Ask({tt}) {
    const location = useLocation();
    const [fileContent, setFileContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');
    const scrollContainerRef = useRef(null);
    const codeRef = useRef();
    const cursorPosition = useRef(0);
    const [text, setText] = useState('');

    const fetchData = async (e) => {
        try {
            const code = document.getElementById('codeBlock').textContent;
            const request = e.target.textContent;
            const message = "Iam the student, my code that i wrote myself is "+ code + (request.includes("Compile") ? " " :"(if no proper code provided after the word \"is\", tell them this phrase exactly: Please submit proper code) ") + request + ", do this by going to the books model answer and reviewing my code, keep in mind i only care about my code, if there is no further editing needed, or the code is correct, just say that!";
            setLoading(true);
            console.log("Message "+message);
            fetch('/api/processMessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: message,
                    url: location.pathname,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    setResponse(`Response: ${data.result}`);
                    scrollToBottom(); // Call scrollToBottom after fetching data
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setResponse(`Error occurred. Please try again`);
                    scrollToBottom(); // Call scrollToBottom on error as well
                });
        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    };


    const scrollToBottom = () => {
        const vh = window.innerHeight;
        const scrollAmount = 0.7 * vh; // 20% of viewport height

        window.scrollTo({
            top: window.scrollY + scrollAmount,
            behavior: 'smooth', // You can remove this line if you want an instant scroll
        });
        Prism.highlightAll();

    };

    useEffect(() => {
        Prism.highlightAll()
    }, [fileContent]); // Trigger Prism highlighting when fileContent changes

    useEffect(() => {
        // Trigger Prism highlighting when fileContent changes
        Prism.highlightAll();

        // Restore the cursor position
        const selection = window.getSelection();
        const range = document.createRange();
        range.setStart(codeRef.current.firstChild, cursorPosition.current);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
    }, [response]);

    // useEffect(() => {
    //     scrollToBottom(); // Call scrollToBottom when response changes
    // }, [message]);
    const handleTextareaChange = (event) => {
        // Check if the Enter key was pressed
        Prism.highlightAll();
        event.target.textContent = event.target.textContent.replaceAll("!=", "!‎=");
    };

    const handleTextareaKey = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();

            // Get the current selection
            const selection = window.getSelection();

            // Get the range of the selection
            const range = selection.getRangeAt(0);

            // Create a text node with 5 spaces
            const spaceNode = document.createTextNode('     ');

            // Replace the selected content with the 5 spaces
            range.deleteContents();
            range.insertNode(spaceNode);

            // Move the caret after the inserted spaces
            range.setStartAfter(spaceNode);
            range.collapse(true);

            // Update the selection
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };


    const handlePaste = (event) => {
        // Prevent the default paste behavior
        event.preventDefault();

        // Get the pasted text
        const pastedText = (event.clipboardData || window.clipboardData).getData('text');

        // Insert the pasted text with preserved line breaks
        document.execCommand('insertText', false, pastedText);

        // Trigger Prism highlighting
        Prism.highlightElement(codeRef.current);
    };
    return (
        <div className={'ai' }>
            <label htmlFor="message">Enter your code:</label>
            <form id="chatForm">
                <div className={"code-viewer flat"}>
                    <pre >
                         <code className="language-java" ref={codeRef}
                               value={""}
                               onPaste={handlePaste}
                               onBlurCapture={handleTextareaChange}
                               onChange={(e) => setText(e.target.value)}
                               onKeyDown={handleTextareaKey}
                               contentEditable={true}
                               id="codeBlock">{text} </code>
                    </pre>
                </div>

            </form>
            <div className={"choice-container"}>
                <button disabled={loading} type="button" className={"button-left"+ (loading? " loading-button": "")} onClick={fetchData}>
                    {!loading && "Check Code"}
                    {loading && <div id="loading" className="lds-ring">
                        <div></div>
                    </div>}
                </button>
                    <button disabled={loading} type="button" className={"button-middle"+ (loading? " loading-button": "")} onClick={fetchData}>
                        {!loading && "Fix the error"}
                        {loading && <div id="loading" className="lds-ring">
                            <div></div>
                        </div>}
                    </button>
                    <button disabled={loading} type="button" className={"button-right"+ (loading? " loading-button": "")} onClick={fetchData}>
                        {!loading && "Complete the code"}
                        {loading && <div id="loading" className="lds-ring">
                            <div></div>
                        </div>}
                    </button>

            </div>

            <div dangerouslySetInnerHTML={{ __html: response }} />
            <div
                style={{ float: 'left', clear: 'both' }}
                ref={(el) => {
                    scrollContainerRef.current = el;
                }}
            ></div>
        </div>
    );
}

export default Ask;