// Import necessary React modules
import React from 'react';
import Social from "../../components/Social";

// Functional component for the About page
const AboutPage = () => {
    return (
        <div id = "about">
            <p>
                Welcome to our smart study hub designed specifically for Kuwait University students
                diving into the world of coding! We're all about making coding easier for you.
                Our AI-driven platform gives you personal help and cool interactive lessons. This site
                was created with love and care by Eng. Youssef Al-Najdi

            </p>
            <div id={"socials"}>
                <Social url={"https://app.baims.com/youssef-alnajdi"} buttonText={"Baims"}/>
                <Social url={"https://wa.me/message/TJLDB4PY5AZFO1"} buttonText={"Whatsapp"}/>
                <Social url={"https://www.instagram.com/barmja.kw/"} buttonText={"Instagram"}/>
            </div>
        </div>
    );
};

// Export the AboutPage component
export default AboutPage;
