import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../styles/ai.css';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-java';
import '../styles/file.css';

function GPT4() {
    const location = useLocation();
    const [fileContent, setFileContent] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');
    const scrollContainerRef = useRef(null);

    const fetchData = async () => {
        try {
            setLoading(true);
            fetch('/api/gpt4', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: message,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    setResponse(`Response: ${data.result}`);
                    scrollToBottom(); // Call scrollToBottom after fetching data
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setResponse(`Error occurred. Please try again`);
                    scrollToBottom(); // Call scrollToBottom on error as well
                });
        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    };

    const handleTextareaChange = (event) => {
        setMessage(event.target.value);
    };

    const scrollToBottom = () => {
        const vh = window.innerHeight;
        const scrollAmount = 0.7 * vh; // 20% of viewport height

        window.scrollTo({
            top: window.scrollY + scrollAmount,
            behavior: 'smooth', // You can remove this line if you want an instant scroll
        });
        Prism.highlightAll();

    };


    return (
        <div className={'ai'}>
            <label htmlFor="message">ASK ZIAD-GPT4:</label>
            <form id="chatForm">
                <textarea
                    id="message"
                    name="message"
                    rows="10"
                    value={message}
                    onChange={handleTextareaChange}
                ></textarea>
                <button disabled={loading} type="button" className={loading? "loading-button": ""} onClick={fetchData}>
                    {!loading && "Ask"}
                    {loading && <div id="loading" className="lds-ring">
                        <div></div>
                    </div>}
                </button>
            </form>

            <div dangerouslySetInnerHTML={{ __html: response }} />
            <div
                style={{ float: 'left', clear: 'both' }}
                ref={(el) => {
                    scrollContainerRef.current = el;
                }}
            ></div>
        </div>
    );
}

export default GPT4;